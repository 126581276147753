import React, { useState, useEffect, useRef } from 'react';

const locations = [
    { name: "Safeway", lat: 37.7572555, lng: -122.2508336 },
];

const svgMarker = {
    path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
    fillColor: "#584EA1",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
};

const JobMap = ({lat,lng}) => {
    const apiKey = 'AIzaSyDcSu2bzf2JJpuihcWCxPT6wFAVe7a451c';
    // const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapRef = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    
        window.initMap = () => {
          setMapLoaded(true);
        };
    
        return () => {
          document.head.removeChild(script);
        };
      }, [apiKey]);

      useEffect(() => {
        if (mapLoaded && mapRef.current) {
          const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: 37.7572555, lng: -122.2508336 },
            zoom: 14,
          });
    
          locations.forEach((location) => {
            new window.google.maps.Marker({
              position: { lat: location.lat, lng: location.lng },
              map: map,
              title: location.name,
            });
          });
        }
      }, [mapLoaded]);

  return (
    <>
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
        <div ref={mapRef} style={{ flex: 1 }}></div>
    </div>
    </>
  );
};

export default JobMap;